$orange-dark: #D74100;
$blue-primary: #000E54;

.ais-Hits-list{
  list-style: none;
}
.ais-SearchBox-form {
  display: flex;
  border: 1px solid #a9adb3;
  &:focus-within {
    outline: 2px solid #000E54;
  }
}
.ais-SearchBox-input {
  width: 100%;
  padding: .75rem;
  border: none;
  &:focus {
    outline: none;
  }
}
.ais-Pagination-list {
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 2rem 0;
}
li.ais-Pagination-item {
  margin-bottom: 0;
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #D5E3F7;
  margin: 0 2px;
  border-radius: 3px;
  & > a {
    text-decoration: none;
    display: block;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    &:hover {
      color: #ffffff;
      background-color: $blue-primary;
    }
  }
}
.ais-SearchBox-reset {
  border: none;
  width: 50px;
  background: $orange-dark;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ais-SearchBox-resetIcon {
  fill: #ffffff;
  height: 20px;
  width: 20px;
}
.ais-RefinementList {
  padding: 2rem;
  background: #ffffff;
  @media screen and (min-width: 768px) {
    margin-right: 1rem;
  }
  &-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
}
.ais-RefinementList-count {
  font-size: 1rem;
  &::before {
    content: "(";
  }
  &::after {
    content: ")";
  }
}
.ais-Hits-list {
  display: flex;
  flex-direction: column;
  column-gap: 1rem;
  row-gap: 1rem;
  padding: 0;
  margin: 0;
  @media screen and (min-width: 768px) {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr)) 
  }
}
.ais-RefinementList-labelText {
  text-transform: capitalize;
  padding-left: .5rem;
}

.result {
  &-section {
    margin: 1rem;
    @media screen and (min-width: 768px) {
      display: grid;
      grid-template-columns: 300px 1fr;
    }
  }
  &-heading {
    font-size: 1.35rem;
    line-height: 2rem;
    margin: 0;
    & > a {
      color: $blue-primary;
      &:hover {
        color: $orange-dark;
      }
    }
  }
}
.card {
  height: 100%;
  &-body {
    & > p {
      font-size: 1.125rem;
      margin: 0;
    }
  }
}